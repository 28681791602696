/* @refresh reload */
import { customElement, noShadowDOM } from 'solid-element'
import { Component, For, Show } from 'solid-js'
import { Tabs } from '@ark-ui/solid'
import TarifeSlider from '@/components/tarife-tabelle/subcomponents/tarife-slider/tarife-slider'
import IconArrow from '@/assets/icons/arrow.svg'

type Props = {
	tabledata: {
		tablabel: string
		uid: number
		tabledata: string
	}[]
}

const TarifeTabelle: Component<Props> = props => {
	noShadowDOM()

	const tableData = () => {
		return props.tabledata.map(tabData => {
			// Split the input data into rows
			const rows = tabData.tabledata.split(/\r?\n/).filter(row => row.trim() !== '')

			// Split each row into cells and remove empty strings or undefined values
			const table = rows.map(row => row.split('|').filter(cell => cell !== '' && cell !== undefined))

			// Transpose the array
			const transposedTable = table[0].map((_, colIndex) =>
				table.map(row => row[colIndex]).filter(cell => cell !== '' && cell !== undefined)
			)

			return { tablabel: tabData.tablabel, uid: tabData.uid, tabledata: transposedTable }
		})
	}

	return (
		<>
			<div class=" ">
				<Tabs.Root lazyMount unmountOnExit defaultValue={tableData()[0].tablabel}>
					<Tabs.List>
						<div class="tabs mx-auto mb-[35px] flex w-fit rounded-[5px] bg-brightgrey">
							<For each={tableData()}>
								{label => (
									<Tabs.Trigger
										class="w-full rounded-full bg-capecod px-[15px] py-[15px] text-white sm:w-auto sm:max-w-[100%] sm:px-[35px] md:px-[65px]"
										value={label.tablabel}>
										{label.tablabel}
									</Tabs.Trigger>
								)}
							</For>
						</div>
					</Tabs.List>
					<For each={tableData()}>
						{table => (
							<Tabs.Content value={table.tablabel}>
								<div class="tabele mt-5 flex gap-[8px]">
									<ul class="w-[140px] grow-0 bg-yellow text-left md:w-[180px]">
										<For each={table.tabledata[0]}>
											{(row, index) => (
												<li
													class="flex h-[65px] items-center p-[5px] pl-[12px] text-left font-bold leading-[1.5] md:pl-[25px]"
													classList={{ 'bg-bwGrey text-white': index() === 0 }}
													// eslint-disable-next-line solid/no-innerhtml
													innerHTML={row}
												/>
											)}
										</For>
									</ul>
									<TarifeSlider
										uid={table.uid}
										sliderSettings={{
											fixedWidth: 140,
											mouseDrag: true,
											loop: false,
											gutter: 8,
											items: 1,
											slideBy: 1,
											speed: 500,
											center: false,
											navPosition: 'bottom',
											controlsContainer: '#controls-' + table.uid,
											prevButton: '#prev-' + table.uid,
											nextButton: '#next-' + table.uid,
											responsive: {
												576: {
													items: 2,
												},
												768: {
													items: 3,
													fixedWidth: 180,
												},
												992: {
													items: 3,
													slideBy: 3,
												},
												1200: {
													items: 4,
												},
												1400: {
													items: 5,
												},
											},
										}}
										elements={{
											controlsContainer: (
												<ul
													id={`controls-${table.uid}`}
													class="controls absolute -bottom-12 left-1/2 z-[5] flex w-auto -translate-x-1/2 transform justify-between md:ml-[-171px] lg:bottom-1/2 lg:ml-0 lg:w-full lg:-translate-y-1/2 [@media(min-width:1275px)]:w-[calc(100%+20px)]">
													<button
														id={`prev-${table.uid}`}
														type="button"
														class="mr-2 !rounded-full !bg-capecod disabled:opacity-25">
														<IconArrow class="w-8 !rotate-180 fill-white" />
													</button>
													<button
														id={`next-${table.uid}`}
														type="button"
														class="ml-2 !rounded-full !bg-capecod disabled:opacity-25 lg:mr-0">
														<IconArrow class="w-8 fill-white" />
													</button>
												</ul>
											),
										}}
										customClasses={{
											wrapperContainer: ['md:relative', 'w-full', 'h-full'],
											tinysliderow: ['w-full', 'h-full', 'overflow-x-clip'],
										}}>
										<For each={table.tabledata}>
											{(column, index) => (
												<Show when={index() > 0}>
													<ul
														classList={{ 'bg-yellow grow-0 w-[230px] text-left': index() === 0 }}
														class="w-auto text-center">
														<For each={column}>
															{(row, index) => (
																<>
																	<li
																		class="flex h-[65px] items-center justify-center bg-brightgrey p-[5px] leading-[1.5]"
																		classList={{
																			'bg-bwGrey text-white font-bold': index() === 0,
																		}}
																		// eslint-disable-next-line solid/no-innerhtml
																		innerHTML={row}
																	/>
																</>
															)}
														</For>
													</ul>
												</Show>
											)}
										</For>
									</TarifeSlider>
								</div>
							</Tabs.Content>
						)}
					</For>
				</Tabs.Root>
			</div>
		</>
	)
}

customElement<Props>(
	'tarife-tabelle',
	{
		tabledata: [],
	},
	TarifeTabelle
)
