import { TinySliderInstance, tns } from 'tiny-slider'
import { createEffect, JSX, onCleanup, onMount, ParentComponent, Show } from 'solid-js'

type Props = {
	uid: number
	sliderSettings?: SilderSettings
	elements: {
		controlsContainer?: JSX.Element
	}
	customClasses: {
		wrapperContainer?: string[]
		tinyslidermw?: string[]
		container?: string[]
		tinyslideriw?: string[]
		tinysliderow?: string[]
	}
	scrollTo?: number
}
const TarifeSlider: ParentComponent<Props> = (props: any) => {
	let slider: TinySliderInstance

	let firstCreate = true

	createEffect(() => {
		props.children()
	})

	createEffect(() => {
		if (props.scrollTo) {
			goTo(props.scrollTo)
		}
	})

	onMount(() => {
		slider = tns({
			container: '#table-' + props.uid,
			...props.sliderSettings,
		})

		if (props.customClasses?.wrapperContainer) {
			document.getElementById(`wrapperContainer-${props.uid}`)?.classList.add(...props.customClasses.wrapperContainer)
		}
		if (props.customClasses?.tinyslidermw) {
			document.getElementById('tinyslider-mw')?.classList.add(...props.customClasses.tinyslidermw)
		}
		if (props.customClasses?.tinysliderow) {
			document.getElementById('tinyslider-ow')?.classList.add(...props.customClasses.tinysliderow)
		}
		if (props.customClasses?.container) {
			document.getElementById(`table-${props.uid}`)?.classList.add(...props.customClasses.container)
		}
		if (props.customClasses?.tinyslideriw) {
			document.getElementById('tinyslider-iw')?.classList.add(...props.customClasses.tinyslideriw)
		}

		window.dispatchEvent(new Event('resize'))
		window.addEventListener('resize', onResize)
	})
	onCleanup(() => {
		slider?.destroy()
		window.removeEventListener('resize', onResize)
	})
	const goTo = (index: number) => {
		slider?.goTo(index)
	}

	const onResize = () => {
		slider.refresh()
		document.querySelector('.tns-liveregion')?.remove()
	}

	return (
		<>
			<div id={`wrapperContainer-${props.uid}`} class="wrapperContainer">
				<Show when={props.elements?.controlsContainer}>{props.elements?.controlsContainer}</Show>
				<Show when={props.elements?.navContainer && !props.sliderSettings.navContainerOuter}>
					{props.elements?.navContainer}
				</Show>
				<div id={`table-${props.uid}`} class="tinyslider tinyslider2 inline-flex">
					{props.children}
				</div>
			</div>
			<Show when={props.elements?.navContainer && props.sliderSettings.navContainerOuter}>
				{props.elements?.navContainer}
			</Show>
		</>
	)
}

interface SilderSettings {
	mode?: 'gallery' | 'carousel'
	axis?: 'horizontal' | 'vertical'
	items?: number
	gutter?: number
	edgePadding?: number
	fixedWidth?: number | false
	autoWidth?: boolean
	slideBy?: number | 'page'
	center?: boolean
	controls?: boolean
	controlPosition?: 'top' | 'bottom'
	controlsText?: string[] | any[]
	controlsContainer?: string | false
	prevButton?: string | false
	nextButton?: string | false
	nav?: boolean
	navPosition?: 'top' | 'bottom'
	navContainer?: string | false
	navAsThumbnails?: boolean
	arrowKeys?: boolean
	speed?: number
	autoplay?: boolean
	autoplayTimeout?: number
	autoplayDirection?: 'forward' | 'backward'
	autoplayText?: string[] | any[]
	autoplayHoverPause?: boolean
	autoplayButton?: string | false
	autoplayButtonOutput?: boolean
	autoplayResetOnVisibility?: boolean
	animateIn?: string
	animateOut?: string
	animateNormal?: string
	animateDelay?: number | false
	loop?: boolean
	rewind?: boolean
	autoHeight?: boolean
	responsive?: any
	lazyload?: boolean
	lazyloadSelector?: string
	touch?: boolean
	mouseDrag?: boolean
	swipeAngle?: number | false
	preventActionWhenRunning?: boolean
	preventScrollOnTouch?: 'auto' | 'force' | false
	nested?: 'inner' | 'outer' | false
	freezable?: boolean
	disable?: boolean
	startIndex?: number
	onInit?: () => void
	useLocalStorage?: boolean
	nonce?: string | false
	navContainerOuter?: boolean
	controlsContainerOuter?: boolean
}

export default TarifeSlider
